import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import "core-js/modules/es.object.to-string.js";
import $ from 'jquery';
export default {
  name: 'Youtube',
  props: {
    src: {
      type: String,
      default: undefined
    }
  },
  created: function created() {
    var _this = this;
    $(document).ready(function () {
      $.getScript("https://www.youtube.com/player_api", function () {
        _this.api_loaded = true;
        if (_this.api_loaded_callback) _this.api_loaded_callback();
      });
    });
  },
  computed: {
    embed_url: function embed_url() {
      if (this.src) {
        //return this.getEmbedURL(this.src)
      }
    }
  },
  mounted: function mounted() {
    if (this.src) this.play(this.src);
  },
  methods: {
    getVideoID: function getVideoID(url) {
      var regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      if (url) {
        var match = url.match(regex);
        if (match) {
          return match[1];
        }
      }
    },
    getEmbedURL: function getEmbedURL(url) {
      var id = this.getVideoID(url);
      if (id) {
        var ret = "https://www.youtube.com/embed/".concat(id, "?autoplay=1");
        return ret;
      }
    },
    onAPILoaded: function onAPILoaded(callback) {
      if (!this.api_loaded) this.api_loaded_callback = callback;else callback();
    },
    setEventListener: function setEventListener(listener) {
      this.eventListener = listener;
    },
    callEvent: function callEvent(event, data) {
      if (this.eventListener) this.eventListener(event, data);
    },
    play: function play(url) {
      var _this2 = this;
      var mute = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
      var startAt = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
      var t1 = Date.now();
      this.paused = undefined;
      // Workaround: when calling "loaded" event the player has not yet seeked to startAt and getCurrentTime will return undefined or some millis
      this.startAt = startAt;
      return new Promise(function (resolve, reject) {
        _this2.onAPILoaded(function () {
          if (!url && _this2.player && _this2.player.getPlayerState() != 1) _this2.player.playVideo();
          if (url) {
            var id = _this2.getVideoID(url);
            if (id) {
              _this2.current_video_id = id;
              window.YT.ready(function () {
                _this2.state = undefined;
                _this2.trying_muted = undefined;
                if (_this2.player) {
                  _this2.player.loadVideoById(id);
                } else {
                  new YT.Player('player', {
                    videoId: id,
                    playerVars: {
                      autoplay: '1',
                      controls: '1'
                    },
                    events: {
                      'onReady': function onReady(event) {
                        if (event.target.playVideo) {
                          if (!_this2.player) event.target.loadVideoById(id); // Call this to make sure onError is called e.g. when Video is private
                          _this2.player = event.target;
                          _this2.callEvent("loaded");
                          if (mute) _this2.player.mute();
                          if (!_this2.paused) _this2.player.playVideo();

                          /*
                          // --- Update player time for seek detection (inspired by https://stackoverflow.com/a/29296014)
                          var interval = 1000;
                           var checkPlayerTime = () => {
                            var state = this.player.getPlayerState()
                            if (state == window.YT.PlayerState.PAUSED) {
                              var player_time = this.player.getCurrentTime()
                              if (player_time != this.player_time)
                                console.log("seeked")
                              this.player_time = player_time
                              setTimeout(checkPlayerTime, interval); /// repeat function call in 1 second
                            }
                          }
                          checkPlayerTime()
                          // ---
                          */
                        }
                      },

                      //'onPlaybackQualityChange': onPlayerPlaybackQualityChange,
                      'onStateChange': function onStateChange(event) {
                        if (_this2.player) {
                          var player_time = parseInt(_this2.player.getCurrentTime()); // Ignore millis
                          if (_this2.paused_player_time && _this2.player_state == window.YT.PlayerState.PAUSED && _this2.paused_player_time != player_time) {
                            _this2.paused_player_time = player_time;
                            // Will be fired when player plays again (seeking while paused won't trigger onStateChange)
                            // So this only works if user slides the seekbar, not clicks on it
                            _this2.callEvent("seeked", {
                              time: player_time
                            });
                          }
                          if (_this2.player) {
                            var player_state_changed = false;
                            if (event.data == window.YT.PlayerState.PLAYING && (_this2.player_state != event.data || _this2.player_state_video_id != _this2.current_video_id)) {
                              _this2.player_state_video_id = _this2.current_video_id;
                              player_state_changed = true;
                              _this2.callEvent("play");
                            }
                            if (event.data == window.YT.PlayerState.PAUSED) {
                              // Problem: if seekbar is clicked on the new time itselve player_time is new time already
                              _this2.paused_player_time = player_time;
                              player_state_changed = true;
                              _this2.callEvent("pause");
                              _this2.wasMuted = _this2.player.isMuted();
                            }
                            // We use this.player_state only for PLAYING and PAUSED events. this.state can also take other states
                            // This will help us to prevent double firing events (e.g. fire PAUSED event if already paused)
                            if (player_state_changed) _this2.player_state = event.data;
                          }
                        }
                        if (_this2.state > 0 && event.data == -1) {
                          // Failed playing "unstarted" > Retry
                          if (event.target.isMuted && !event.target.isMuted()) {
                            // If Video was not muted, retry muted
                            _this2.trying_muted = true;
                            event.target.mute();
                          }
                          event.target.playVideo();
                        } else if (event.data == 1 && event.target.playVideo) {
                          _this2.player = event.target;
                          if (startAt != undefined && startAt > 0) {
                            var t2 = Date.now();
                            var loading_time = (t2 - t1) / 1000;
                            _this2.player.seekTo(startAt + loading_time, true);
                            startAt = undefined;
                            _this2.startAt = undefined;
                          }
                          if (_this2.trying_muted && _this2.player.unMute && !mute && !_this2.wasMuted) _this2.player.unMute();
                        } else if (event.data == 0) {
                          _this2.player_state_video_id = undefined;
                          resolve();
                        }
                        _this2.state = event.data;
                      },
                      'onError': function onError(event) {
                        // Let the error message stay for a few seconds
                        setTimeout(function () {
                          // -- If onError is called the iframe is unusable => create new player instance
                          // 20.08.2023 22:30 - This does not work in player. If video is unavailable (e.g. https://youtu.be/gxYWfO3mzwA) and onError is called next youtube video won't work
                          //var tag = document.createElement('div');
                          //tag.id = 'player'
                          //$('#player').replaceWith(tag)
                          _this2.player_state_video_id = undefined;
                          _this2.player = undefined;
                          // --
                          reject(event.data);
                        }, 5000);
                      }
                    }
                  });
                }
              });
            }
          }
        });
      });
    },
    isPlaying: function isPlaying() {
      return this.player && this.player.getPlayerState() == 1;
    },
    isPaused: function isPaused() {
      return this.player && this.player.getPlayerState() == 2;
    },
    pause: function pause() {
      if (this.player) {
        this.wasMuted = this.player.isMuted();
        this.player.pauseVideo();
      } else this.paused = true;
    },
    stop: function stop() {
      if (this.player) this.player.stopVideo();else this.paused = true;
      this.player_state_video_id = undefined;
      this.callEvent("stopped");
      var tag = document.createElement('div');
      tag.id = 'player';
      $('#player').replaceWith(tag);
      this.player = undefined;
    },
    seekTo: function seekTo(sec) {
      if (this.player) this.player.seekTo(sec, true);
    },
    fastforward: function fastforward() {
      var sec = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
      if (this.player) {
        var current_time = this.player.getCurrentTime();
        this.player.seekTo(current_time + sec, true);
      }
    },
    rewind: function rewind() {
      var sec = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 10;
      if (this.player) {
        var current_time = this.player.getCurrentTime();
        this.player.seekTo(current_time - sec, true);
      }
    },
    getCurrentTime: function getCurrentTime() {
      if (this.startAt && !this.isPlaying() && !this.isPaused()) return this.startAt;
      if (this.player) return this.player.getCurrentTime();
    }
  }
};