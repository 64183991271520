var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container",
    staticStyle: {
      "max-height": "100vh"
    }
  }, [_c("h2", {}, [_vm._v(_vm._s(_vm.$t("configure_screen")))]), _vm._v(" " + _vm._s(_vm.$t("scan_code")) + " "), _c("qrcode-vue", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.registration_data.registration_url,
      expression: "registration_data.registration_url"
    }],
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      value: _vm.registration_data.registration_url,
      size: _vm.qr_size,
      level: "H",
      background: "rgba(0,0,0,0)"
    }
  }), _c("div", {
    staticStyle: {
      "margin-top": "10px"
    },
    domProps: {
      innerHTML: _vm._s(_vm.$t("enter_code", {
        manual_registration_url: _vm.registration_data.manual_registration_url,
        code: _vm.registration_data.code
      }))
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };