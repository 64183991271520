import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    attrs: {
      id: "app"
    }
  }, [_c("link", {
    attrs: {
      rel: "stylesheet",
      href: "".concat(_vm.getBaseURL(), "/v1/styles/index.css?c=").concat(_vm.reload_css)
    }
  }), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoggedIn() || _vm.verification_data || _vm.isPlaying() || _vm.play_local || !_vm.registration_data && _vm.can_play_offline,
      expression: "isLoggedIn() || verification_data || isPlaying() || play_local || (!registration_data && can_play_offline)"
    }]
  }, [_vm.verification_data ? _c("Verify", {
    staticClass: "center",
    attrs: {
      verification_data: _vm.verification_data
    }
  }) : _vm._e(), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.verification_data || _vm.isPlaying() || _vm.play_local || !_vm.verification_data && _vm.can_play_offline,
      expression: "!verification_data || isPlaying() || play_local || (!verification_data && can_play_offline)"
    }],
    staticStyle: {
      background: "#000",
      width: "100vw",
      height: "100vh"
    }
  }, [_c("player", {
    ref: "player",
    on: {
      onStatusChanged: _vm.onPlayerStatusChanged
    }
  })], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isPlaying() && !_vm.verification_data,
      expression: "!isPlaying() && !verification_data"
    }]
  }, [!_vm.isLoggedIn() && _vm.registration_data && _vm.connected && !_vm.play_local ? _c("div", {
    staticClass: "center"
  }, [_c("Login", {
    attrs: {
      registration_data: _vm.registration_data
    }
  })], 1) : !_vm.isLoggedIn() && !_vm.play_local && (!_vm.registration_data || !_vm.connected) && !_vm.can_play_offline ? _c("div", {
    staticClass: "center"
  }, [_c("h2", [_vm._v(_vm._s(_vm.$t("waiting_for_server")))])]) : _vm._e()])]);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };