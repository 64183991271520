import QrcodeVue from 'qrcode.vue';
export default {
  name: 'Verify',
  components: {
    QrcodeVue: QrcodeVue
  },
  computed: {
    qr_size: function qr_size() {
      var ret = this.innerWidth / 100 * 90;
      if (ret > this.innerHeight / 100 * 50) ret = this.innerHeight / 100 * 50;
      return ret;
    }
  },
  props: {
    verification_data: {
      type: Object,
      default: function _default() {
        return undefined;
      }
    }
  },
  data: function data() {
    return {
      innerWidth: window.innerWidth,
      innerHeight: window.innerHeight
    };
  },
  created: function created() {
    var _this = this;
    window.addEventListener("resize", function () {
      _this.innerWidth = window.innerWidth;
      _this.innerHeight = window.innerHeight;
    });
  }
};