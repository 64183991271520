var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "app-container"
  }, [_c("h2", {}, [_vm._v(_vm._s(_vm.$t("screen_verification")))]), _c("qrcode-vue", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.verification_data.verification_code,
      expression: "verification_data.verification_code"
    }],
    staticStyle: {
      "margin-top": "10px"
    },
    attrs: {
      value: _vm.verification_data.verification_code,
      size: _vm.qr_size,
      level: "H",
      background: "rgba(0,0,0,0)"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };