import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticStyle: {
      background: "#000"
    },
    attrs: {
      id: "wrapper"
    }
  }, [_c("div", {
    staticStyle: {
      height: "1in",
      width: "1in",
      left: "100%",
      position: "fixed",
      top: "100%"
    },
    attrs: {
      id: "dpi"
    }
  }), _c("div", {
    staticStyle: {
      height: "100vh",
      width: "100vw",
      "background-color": "#ccff00"
    },
    attrs: {
      id: "fallback"
    }
  }, [_c("div", {
    staticClass: "center",
    style: "font-size: " + _vm.qr_size / 10 + "px; font-weight: bold;"
  }, [_vm.fallback_text ? _c("div", {
    domProps: {
      innerHTML: _vm._s(_vm.fallback_text)
    }
  }) : _vm._e(), _c("qrcode-vue", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.fallback_qr_url,
      expression: "fallback_qr_url"
    }],
    staticStyle: {
      "margin-top": "20px"
    },
    attrs: {
      value: _vm.fallback_qr_url,
      size: _vm.qr_size,
      level: "H",
      background: "rgba(0,0,0,0)"
    }
  })], 1), _c("div", {
    style: "position: absolute; bottom: 20px; right: 20px; font-size: " + _vm.qr_size / 15 + "px; text-align: center;"
  }, [_vm.date ? _c("div", [_vm._v(_vm._s(_vm.date))]) : _vm._e(), _vm.time ? _c("div", [_vm._v(_vm._s(_vm.time))]) : _vm._e()])]), _c("svg", {
    staticClass: "center",
    staticStyle: {
      display: "none",
      "z-index": "10",
      width: "5em"
    },
    attrs: {
      id: "loading",
      "aria-hidden": "true"
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "#icon-loading"
    }
  })]), _c("pinch-zoom", {
    staticClass: "viewer",
    attrs: {
      id: "img_wrapper"
    }
  }, [_c("img", {
    staticClass: "viewer",
    staticStyle: {
      position: "relative !important"
    },
    attrs: {
      id: "img"
    }
  })]), _c("video", {
    staticClass: "viewer",
    attrs: {
      id: "video_stream"
    }
  }), _c("video", {
    staticClass: "viewer",
    attrs: {
      id: "video",
      preload: "auto"
    }
  }), _vm._m(0), _c("youtube", {
    ref: "youtube",
    staticClass: "viewer",
    attrs: {
      id: "youtube"
    }
  }), _c("div", {
    staticClass: "viewer",
    staticStyle: {
      height: "100vh",
      width: "100vw",
      margin: "0px",
      padding: "0px",
      position: "absolute"
    },
    attrs: {
      id: "iframe",
      marginwidth: "0",
      marginheight: "0",
      frameBorder: "0px"
    },
    domProps: {
      innerHTML: _vm._s(_vm.html_content)
    }
  }), _c("pdf", {
    ref: "pdf",
    staticClass: "viewer",
    staticStyle: {
      position: "absolute"
    },
    attrs: {
      id: "pdf",
      src: _vm.pdf_src,
      page: _vm.pdf_page
    }
  }), _c("div", {
    ref: "watermark",
    staticClass: "watermark clickable",
    attrs: {
      id: "watermark"
    },
    on: {
      click: function click($event) {
        _vm.openURL(_vm.getEnv().VUE_APP_WEB_URL);
      }
    }
  }, [_c("div", {
    staticClass: "flex"
  }, [_c("div", {
    staticClass: "flex-child",
    style: "margin-top: ".concat(this.innerWidth / 100 * 3, "px;")
  }, [_c("qrcode-vue", {
    staticClass: "qr",
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      value: _vm.getEnv().VUE_APP_WEB_URL,
      size: this.innerWidth / 100 * 7,
      level: "H",
      foreground: "#000000",
      background: "#ffffff"
    }
  })], 1), _c("div", {
    staticClass: "flex-child branding"
  }, [_c("div", {
    style: "font-size: ".concat(this.innerWidth / 100, "px;")
  }, [_vm._v("DIGITAL SIGNAGE"), _c("br"), _vm._v("POWERED BY")]), _c("svg-icon", {
    style: "width: ".concat(Math.floor(this.innerWidth / 100 * 7 * 200 / 115), "px; height: ").concat(Math.floor(this.innerWidth / 100 * 7), "px;"),
    attrs: {
      "icon-class": "logo"
    }
  })], 1)])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.breaking_news && _vm.breaking_news.text,
      expression: "breaking_news && breaking_news.text"
    }],
    staticClass: "breaking-news",
    style: _vm.breaking_news.style,
    attrs: {
      id: "breaking-news"
    }
  }, [_c("span", {
    style: _vm.breaking_news.animStyle,
    domProps: {
      innerHTML: _vm._s(_vm.breaking_news.text)
    }
  })])], 1);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "viewer",
    attrs: {
      id: "video_overlay"
    }
  }, [_c("div", {
    staticClass: "subtitles",
    attrs: {
      id: "subtitles"
    }
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };